






























	import {Component, Prop, Vue} from 'vue-property-decorator';

	@Component
	export default class extends Vue {
		@Prop({default: 'Main title'}) title!: string;
		@Prop({default: 'Sub title'}) text1!: string;
		@Prop({default: 'Sub title'}) text2!: string;
		@Prop({default: '/images/truck1.jpg'}) imgUrl!: string;

		get img() { return `url(${this.imgUrl})`; }
	}
