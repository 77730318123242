import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VOverlay } from 'vuetify/lib/components/VOverlay';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"host-hestia card mb-3"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-5"},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"card-img",style:({backgroundImage: _vm.img}),attrs:{"elevation":"9"}},[_c(VFadeTransition,[(hover)?_c(VOverlay,{attrs:{"absolute":"","color":"grey darken-4"}},[_c(VBtn,{attrs:{"color":"pink"}},[_vm._v("Find out more")])],1):_vm._e()],1)],1)]}}])})],1),_c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"text-muted"},[_vm._t("default")],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }