




















	import {Component, Prop, Vue} from 'vue-property-decorator';

	@Component
	export default class extends Vue {
		@Prop({default: 'Main title'}) title!: string;
		@Prop({default: ''}) text1!: string;
		@Prop({default: ''}) text2!: string;
		@Prop({default: '/images/truck1.jpg'}) imgUrl!: string;
	}
