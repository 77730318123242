










	import {Component, Vue} from 'vue-property-decorator';
	import HelloWorld from '@/components/HelloWorld.vue'
	import Overview from "@/views/services/Overview.vue";
	import Overview2 from "@/views/services/Overview2.vue";

	@Component({components: {Overview, Overview2, HelloWorld}})
	export default class Services extends Vue {
		name = 'Dom'
	}
