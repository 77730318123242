import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"service-card",attrs:{"outlined":""}},[_c(VImg,{staticClass:"white--text align-end",attrs:{"src":_vm.imgUrl,"max-height":"200"}},[_c(VCardTitle,{staticClass:"gm-title"},[_c('i',[_vm._v(_vm._s(_vm.title))])])],1),(_vm.text1)?_c(VCardSubtitle,{staticClass:"pb-0"},[_vm._v(_vm._s(_vm.text1))]):_vm._e(),_c(VCardText,{staticClass:"gm-card-body text-muted"},[_vm._t("default")],2),_c(VCardActions,[_c(VBtn,{attrs:{"color":"orange","text":""}},[_vm._v("Find out more")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }